import { Event, day, month, EventLevel } from '~/src/calendar/helpers.ts';

export default [
    // --- months ---
    new Event('Queer History Month (Finland)', 'Progress Pride', 11, month, EventLevel.Month),

    // --- static date ---
    new Event('Marriage Equality Day (Sweden)', '_hrc', 5, day(1), EventLevel.Day),
    new Event('Marriage Equality Day (Finland)', '_hrc', 3, day(1), EventLevel.Day),

];
